import React from "react"
import "./classPacks.scss"

const ClassPacks = ({
  mainHeading,
  membershipHeading,
  membershipCard,
  underCardHeading,
  underCardSubheading,
  classPacksHeading,
  classPackCard,
  tCsTop,
  tCsBottomText,
  strokeAfterTitle,
  strokeBeforeTitle,
  solidTitle,
}) => {
  return (
    <section className="class-packs-section">
      <div className="container">
        <div className="class-packs-wrapper">
          <div className="class-packs__title">
            <div className="class-packs__shadow-title">
              <h2 className="class-packs__shadow-text" data-effect="Classes">
                {mainHeading}
              </h2>
            </div>
          </div>

          <div className="class-packs__unlimited-classes__wrapper">
            <h3 className="class-packs__unlimited-classes__title">
              {membershipHeading}
            </h3>
            <div className="class-packs__unlimited-classes">
              {membershipCard.map((memberCard, key) => {
                return (
                  <>
                    <div
                      className={`class-packs__unlimited-classes__card ${
                        memberCard.colorScheme === "Yellow"
                          ? "yellow__theme"
                          : memberCard.colorScheme === "Purple"
                          ? "purple__theme"
                          : null
                      }`}
                      key={key}
                    >
                      {/* sale box */}
                      {memberCard.promoText ? (
                        <div className="class-packs__unlimited-classes__card__recommend-text">
                          <h5>{memberCard.promoText}</h5>
                        </div>
                      ) : null}

                      {/* sale box end */}
                      {memberCard.cardType === true ? (
                        <>
                          <div className="class-card-type-2">
                            <h4 className="class-packs__unlimited-classes__card__title">
                              {memberCard.number} {memberCard.term}
                            </h4>
                            <div className="class-packs__unlimited-classes__card__subtitle">
                              <p className="first-word">{memberCard.number}</p>
                              <div>
                                <p>%</p>
                                <p>{memberCard.percentageText}</p>
                              </div>
                            </div>
                            <h5 className="class-packs__unlimited-classes__card__desc">
                              {memberCard.topSubText}
                              <br />
                              {memberCard.bottomSubText}
                            </h5>
                            {memberCard.features ? (
                              <>
                                <ul className="class-packs__unlimited-classes__card__benefit-list">
                                  {memberCard.features.map((feature, key) => {
                                    return (
                                      <>
                                        <li key={key}>
                                          <div className="class-packs__unlimited-classes__card__benefit-list__icon"></div>
                                          {feature.featureItem}
                                        </li>
                                      </>
                                    )
                                  })}
                                </ul>
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="class-packs__unlimited-classes__card__top-content class-card-type-1">
                            <p className="class-packs__unlimited-classes__card__title">
                              {memberCard.number}
                            </p>
                            <h5 className="class-packs__unlimited-classes__card__subtitle">
                              {memberCard.term}
                            </h5>
                            {memberCard.features ? (
                              <>
                                <ul className="class-packs__unlimited-classes__card__benefit-list">
                                  {memberCard.features.map((feature, key) => {
                                    return (
                                      <>
                                        <li key={key}>
                                          <div className="class-packs__unlimited-classes__card__benefit-list__icon"></div>
                                          {feature.featureItem}
                                        </li>
                                      </>
                                    )
                                  })}
                                </ul>
                              </>
                            ) : null}
                          </div>
                        </>
                      )}

                      <div className="class-packs__unlimited-classes__card__price__wrapper">
                        <div className="class-packs__unlimited-classes__card__price__content">
                          <h6 className="class-packs__unlimited-classes__card__sub-price">
                            {memberCard.totalPrice}
                          </h6>
                          <h5 className="class-packs__unlimited-classes__card__main-price">
                            {memberCard.cyclePrice}
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </>
                )
              })}
              {/* start card */}
              {/* card type 1 */}
            </div>
          </div>

          <h3 className="class-packs__cta-1st">{underCardHeading}</h3>
          <h3 className="class-packs__cta-2st">{underCardSubheading}</h3>
          <h3 className="class-packs__flexible-packages__title">
            {classPacksHeading}
          </h3>

          {/* second set of cards per class packs */}
          <div className="class-packs__unlimited-classes__wrapper">
            <div className="class-packs__unlimited-classes per__class">
              {classPackCard.map((classCard, key) => {
                return (
                  <>
                    <div className="per__class__card" key={key}>
                      <div className="class-packs__unlimited-classes__card__top-content per__class__top ">
                        <h4 className="class-packs__unlimited-classes__card__title per__class__title">
                          {classCard.cardHeading}
                        </h4>
                        <div className="per__class__numbers">
                          <span className="per__class__amount">
                            {classCard.number}
                          </span>
                          {classCard.percentageSignYesno === true ? (
                            <span className="per__class__percent">%</span>
                          ) : null}

                          <span className="per__class__second-text">
                            {classCard.numberSubText}
                          </span>
                        </div>
                        <div className="per__class__terms">
                          <span className="top-heading">
                            {classCard.descriptionTopText}
                          </span>
                          <span className="bottom-heading">
                            {classCard.descriptionBottomText}
                          </span>
                        </div>
                      </div>
                      <div className="per__class__price__wrapper">
                        <div className="per__class__content">
                          <h6 className="per__class__sub-price">
                            {classCard.topPrice}
                          </h6>
                          <h5 className="per__class__main-price">
                            {classCard.bottomPrice}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="tcs__text">
            <div>
              <p>{tCsTop}</p>
              <p>{tCsBottomText}</p>
            </div>
            <h3
              className="stroke-text"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="10"
            >
              {" "}
              {strokeBeforeTitle}
              <span className="solid">{solidTitle}</span>
              {strokeAfterTitle}
            </h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClassPacks
