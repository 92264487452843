import { graphql } from "gatsby"
import React, { useState } from "react"
import ClassPacks from "../components/ClassPass/ClassPacks"

import {
  Banner,
  Cta,
  ErrorBoundary,
  Hero,
  Layout,
  MindBodyPage,
  Seo,
  TextBlocks,
  TitleText,
  Benefit,
  FormSendMailchimp,
} from "../../index.js"

function Memberships({ data }) {
  let { heroTitle, heroSubTitle, heroImage } =
    data.memberships.blocks[0].heroBlock
  let titleText = data.memberships.blocks[1].textBlock
  let titleText2 = data.memberships.blocks[2].textBlock
  let classPacksData = data.memberships.blocks[3].classPacks
  //   let classPassData = data.memberships.blocks[2].classPassBlock
  let benefitData = data.memberships.blocks[3].textBlock
  let cta = data.memberships.blocks[4].ctaBlock
  let { textBlockTitle, textBlockSubTitle, textCard, buttonText } =
    data.memberships.blocks[5].textBlock
  let formData = data.memberships.blocks[6].formBlock
  let seoTitle = data.memberships.seo.title
  let metaDesc = data.memberships.seo.metaDesc

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }
  return (
    <Layout>
      <Seo
        title={data.memberships.seo?.title}
        description={data.memberships.seo?.metaDesc}
        featuredImage={
          data.memberships.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <Hero
        heroImage={heroImage}
        heroTitle={{ normalTitle: heroTitle, strokeTitle: heroSubTitle }}
        color="#bfa0ff"
        className="memberships-hero-customize"
      />

      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <TitleText
        titleText={titleText}
        text={titleText.buttonText}
        onClick={onClick}
      />
      <TextBlocks
        TextBlock={2}
        textBlockTitle={titleText2.textBlockTitle}
        textBlockSubTitle={titleText2.textBlockSubTitle}
        textCard={titleText2.textCard}
        className="memberships-text-block-customize"
        buttonText={titleText2.buttonText}
        buttonClass="light"
        mailtoButton={true}
      />
      {classPacksData && (
        <ClassPacks
          mainHeading={classPacksData.mainTitle}
          membershipHeading={classPacksData.membershipPriceHeading}
          membershipCard={classPacksData.pricingCard}
          underCardHeading={classPacksData.underCardHeading}
          underCardSubheading={classPacksData.underCardSubHeading}
          classPacksHeading={classPacksData.classPacksHeading}
          classPackCard={classPacksData.classPackCard}
          tCsTop={classPacksData.tCsTop}
          tCsBottomText={classPacksData.tCsBottomText}
          underCardsTitle={classPacksData.underCardsTitle}
          strokeAfterTitle={classPacksData.strokeAfterTitle}
          strokeBeforeTitle={classPacksData.strokeBeforeTitle}
          solidTitle={classPacksData.solidTitle}
        />
      )}
      <Benefit
        title={benefitData.textBlockSubTitle}
        benefit={benefitData.textCard}
        backgroundImage={benefitData.backgroundImage}
      />
      {cta && (
        <Cta
          cta={cta}
          textColor="#914cff"
          className="memberships-cta-custom"
          anchor="membershipForm"
          onClick={onClick}
        />
      )}
      {data.memberships.blocks[5].textBlock && (
        <TextBlocks
          TextBlock={2}
          textBlockTitle={textBlockTitle}
          textBlockSubTitle={textBlockSubTitle}
          textCard={textCard}
          className="memberships-text-block-customize"
          buttonText={buttonText}
          buttonClass="light"
          mailtoButton={true}
        />
      )}
      {formData && (
        <FormSendMailchimp
          form={formData}
          formClassName="custom-form"
          buttonClassName="custom-button"
        />
      )}
    </Layout>
  )
}

export default Memberships
export const query = graphql`
  query ($slug: String!, $lang: String!) {
    memberships: wpPage(
      slug: { eq: $slug }
      language: { slug: { eq: $lang } }
    ) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTextBlock {
          textBlock {
            textBlockTitle
            textBlockTitleStroke
            textBlockSubTitle
            buttonText
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
              }
            }
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(fit: COVER, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfClassPacksBlock {
          classPacks {
            pricingCard {
              bottomSubText
              cardType
              cyclePrice
              features {
                featureItem
              }
              number
              percentageNumber
              percentageText
              term
              topSubText
              totalPrice
              promoText
              colorScheme
            }
            membershipPriceHeading
            classPackCard {
              bottomPrice
              cardHeading
              descriptionBottomText
              descriptionTopText
              number
              numberSubText
              percentageSignYesno
              topPrice
            }
            tCsBottomText
            tCsTop
            underCardHeading
            underCardSubHeading
            classPacksHeading
            mainTitle
            solidTitle
            strokeAfterTitle
            strokeBeforeTitle
          }
        }
        ... on WpAcfClassPassBlock {
          classPassBlock {
            classPassTitle
            classPass {
              classPassAmount
              classPassName
              classPassPrice
              classPassValid
              totalPrice
            }
          }
        }
        ... on WpAcfCtaBlock {
          ctaBlock {
            ctaTitle
            ctaStrokeTitle
            ctaButton {
              target
              title
              url
            }
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
